import { createStore } from "vuex";
import vuexPersist from "@/store/persit";
import SimModule from "@/store/sim";
import ResourceModule from "@/store/resource";
import AuthModule from "@/store/auth";
import MemberModule from "@/store/member";
import MessageModule from "@/store/message";
import RoleModule from "@/store/role";
import ManualModule from "@/store/manual";
import AutomationModule from "@/store/automation";
import LoadingModule from "@/store/loading";
import PaginateModule from "@/store/paginate";
import FilterModule from "@/store/filter";
import WebSocketModule from "@/store/websocket";
import RecievedSimDataModule from "@/store/recieved-sim-data";
import Notification from "@/store/notification";
import SortModule from "@/store/sort";
import OtaModule from "@/store/ota";
import OtaTableModule from "@/store/ota-table";
import TableViewModule from "@/store/table-view";
import GoogleMapModule from "@/store/google-map";

export default createStore({
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    sim: SimModule,
    resource: ResourceModule,
    auth: AuthModule,
    member: MemberModule,
    message: MessageModule,
    role: RoleModule,
    manual: ManualModule,
    automation: AutomationModule,
    loading: LoadingModule,
    paginate: PaginateModule,
    filter: FilterModule,
    websocket: WebSocketModule,
    recievedSimData: RecievedSimDataModule,
    notification: Notification,
    sort: SortModule,
    ota: OtaModule,
    otaTable: OtaTableModule,
    tableView: TableViewModule,
    googleMap: GoogleMapModule,
  },
  plugins: [vuexPersist.plugin],
});
