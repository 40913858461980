import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

type StringUnsafe = string | undefined;
export default function useByteField(configs: { label: string; length: number }) {
  const { label, length } = configs;
  const { t } = useI18n();

  const value = ref("");
  const errorMessage = computed(() => {
    if (value.value.length === 0) {
      return t("ota.validate.requiredByteField", { label });
    }
    if (!validateHex(value.value)) {
      return t("ota.validate.invalidHexFormat");
    }
    if (!validateByteLength(value.value, length)) {
      return t("ota.validate.invalidByteLength", { length });
    }
    return "";
  });

  const toSafeString = (input: StringUnsafe): string => {
    return input ?? "";
  };

  const isEvenNumber = (value: number): boolean => {
    return value % 2 === 0;
  };

  const validateHex = (input: StringUnsafe): boolean => {
    const _input = toSafeString(input);
    const res = _input.match(/[0-9a-fA-F]*/);
    if (res && res[0] === _input && isEvenNumber(_input.length)) {
      return true;
    }
    return false;
  };

  const validateByteLength = (input: StringUnsafe, byteLength: number): boolean => {
    const _input = toSafeString(input);
    if (_input.length / 2 !== byteLength) {
      return false;
    }
    return true;
  };

  return {
    value,
    errorMessage,
  };
}
