import request from "@/services/common-request";
import { PutRMFConfigDetailsBody, GetRMFConfigDetailsResponse } from "@/types/multi-imsi";
import { otaUri } from "./uri";
import { OTAStatus, ManagedPackageAID, SnapshotFilter, CreateBulkOTATaskPayload, CreateBulkOTATaskCommonPayload } from "@/types";
import { JCLoadFileOrm } from "../../../common/src/models/postgres/jcLoadFile";

request.defaults.baseURL = otaUri;
const endpoint = "/packages";

export const getOtas = (params: any): Promise<any> => {
  return request.get(`${endpoint}/list`, { params });
};

export const updateOta = (packageAID: string, packageName: string, otaVersion: string, params: any): Promise<any> => {
  return request.put(`${endpoint}/update/${packageName}/${packageAID}/${otaVersion}`, params);
};

export const createOta = (params: any): Promise<JCLoadFileOrm> => {
  const form = new FormData();
  Object.keys(params).forEach((key: string) => {
    form.append(key, params[key]);
  });
  return request.post(`${endpoint}/upload`, form);
};

export const addPackage = (payload: { packageAID: string; form: { label: string; capFile: File } }): Promise<JCLoadFileOrm> => {
  const form = new FormData();
  Object.keys(payload.form).forEach((key: string) => {
    form.append(key, payload.form[key]);
  });
  return request.post(`${endpoint}/upload/${payload.packageAID}`, form);
};

export const exportOta = (params: any): Promise<any> => {
  return request.post(`${endpoint}/export`, params, { responseType: "blob" });
};

export const deleteOta = (packageAID: string, packageName: string, otaVersion: string): Promise<any> => {
  return request.delete(`${endpoint}/delete/${packageName}/${packageAID}/${otaVersion}`);
};

export const duplicatePackage = (params: { id: number; label: string }): Promise<JCLoadFileOrm> => {
  return request.post(`${endpoint}/duplicate`, params);
};

export const getListManagedPackageAIDs = (): Promise<ManagedPackageAID[]> => {
  return request.get(`${endpoint}/managedPackageAIDs`);
};

export const deleteManagedPackageAIDs = (packageAIDs: string[]): Promise<void> => {
  return request.delete(`${endpoint}/managedPackageAIDs`, { data: { ids: packageAIDs } });
};

export const updateOrCreateManagedPackageAIDs = (packageAID: string, label: string): Promise<any> => {
  return request.put(`${endpoint}/managedPackageAID/update/${packageAID}`, { label });
};

export const getOTAStatus = (packageAID: string, packageName: string, otaVersion: string): Promise<OTAStatus> => {
  return request.get(`${endpoint}/status/${packageName}/${packageAID}/${otaVersion}`);
};

export const getLogOta = (params: {
  simId?: number;
  status?: string;
  otaSettingId?: number;
  skip?: number;
  take?: number;
  pageIndex?: number;
}): Promise<any> => {
  return request.get(`admin/log`, { params });
};

export const getPagedLogOta = (
  direction: string,
  iccid?: string,
  status?: string,
  packageName?: string,
  packageAID?: string,
  otaVersion?: string,
  lastId?: number,
  limit?: number,
): Promise<any> => {
  return request.get(`admin/pagedLog`, { params: { direction, iccid, status, packageName, packageAID, otaVersion, lastId, limit } });
};

export const getActivePackageVersions = (): Promise<any> => {
  return request.get(`/admin/activePackageVersions`);
};

export const setActivePackageVersion = (packageAID: string, packageName: string, otaVersion: string | null): Promise<any> => {
  return request.put(`/admin/setActiveVersion`, { packageAID, packageName, otaVersion: otaVersion });
};

export const getManagerICCIDs = (): Promise<any> => {
  return request.get(`/admin/managedICCIDs`, {});
};

export const getAdminListFailureEvents = (packageAID: string, packageName: string, otaVersion: string): Promise<any> => {
  return request.get(`/admin/listFailureEvents`, { params: { packageAID, packageName, otaVersion } });
};

export const getListICCIDbyInstalledPackage = (packageAID: string, packageName: string, otaVersion: string): Promise<any> => {
  return request.get(`/admin/listICCIDbyInstalledPackage`, { params: { packageAID, packageName, otaVersion } });
};

export const createSnapshotOfOTATarget = (filter: SnapshotFilter): Promise<{ key: string }> => {
  return request.post(`/admin/createSnapshotOfOTATarget`, filter);
};

export const getSnapshotCSVSignedUrl = (key: string): Promise<{ signedUrl: string }> => {
  return request.get(`/admin/getSnapshotCSVSignedUrl/${key}`);
};

export const getBulkOTAResultCSVSignedUrl = (key: string): Promise<{ signedUrl: string }> => {
  return request.get(`/admin/getBulkOTAResultCSVSignedUrl/${key}`);
};

export const createBulkOTATask = (payload: CreateBulkOTATaskPayload): Promise<{ nextChunkId?: number }> => {
  return request.put(`/admin/bulkOTA`, payload);
};

export const createBulkOTATaskByIccid = (
  iccid: string,
  payload: {
    simOTA: {
      pollerId?: number;
      appletId?: number;
    };
    targetPackage: CreateBulkOTATaskCommonPayload;
  },
): Promise<void> => {
  return request.put(`/admin/bulkOTA/${iccid}`, payload);
};

const storeDataPath = (path: string): string => {
  return `/remoteconfig/package${path}`;
};
export const getStoreDataApdus = (packageAID: string, packageName: string, otaVersion: string, appletInstanceAID: string) => {
  return request.get(storeDataPath("/get"), { params: { packageAID, packageName, otaVersion, appletInstanceAID } });
};
export type ConfigApdu = {
  canFail?: boolean;
  apdu: string;
};
type UpdateStoreDataBody = {
  tag?: string;
  configAPDUs: ConfigApdu[];
};
export const updateStoreDataCommands = (packageAID: string, packageName: string, otaVersion: string, appletInstanceAID: string, data: UpdateStoreDataBody) => {
  return request.put(storeDataPath(`/update/${packageName}/${packageAID}/${otaVersion}/${appletInstanceAID}`), data);
};

const rfmPrefix = "/remote-file-management-config/package";
export const getRemoteFileManagementConfigDetails = (
  packageAID: string,
  packageName: string,
  otaVersion: string,
  appletInstanceAID: string,
): Promise<GetRMFConfigDetailsResponse> => {
  return request.get(`${rfmPrefix}/get`, {
    params: { packageAID, packageName, otaVersion, appletInstanceAID },
  });
};
export const putRemoteFileManagementConfigDetails = (
  packageAID: string,
  packageName: string,
  otaVersion: string,
  appletInstanceAID: string,
  data: PutRMFConfigDetailsBody,
) => {
  return request.put(`${rfmPrefix}/update/${packageName}/${packageAID}/${otaVersion}/${appletInstanceAID}`, data);
};
