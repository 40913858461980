<template>
  <v-footer class="bg-transparent" padless>
    <v-row class="text-center pt-6">
      <v-col cols="12" class="d-flex align-center justify-center">
        <copyright color="black" size="small" />
      </v-col>
    </v-row>
  </v-footer>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useConfig, useUserSetting } from "@/composables";
import Copyright from "@/components/BaseComponent/Copyright.vue";
export default defineComponent({
  name: "FooterBar",
  components: {
    Copyright,
  },
  setup() {
    const { APP_ENV, APP_ENVIRONMENT_PROD, APP_VERSION } = useConfig();
    const isProduction = computed(() => APP_ENV === APP_ENVIRONMENT_PROD);
    return {
      APP_VERSION,
      isProduction,
    };
  },
});
</script>
