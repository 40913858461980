import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4082a164"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("a", {
      target: "_blank",
      class: _normalizeClass(`${_ctx.textColor} ${_ctx.colorClass} ${_ctx.sizeClass}`),
      href: _ctx.COPYRIGHT_URI
    }, _toDisplayString(_ctx.$t("app_copyright")), 11, _hoisted_1),
    _createElementVNode("span", {
      class: _normalizeClass(`${_ctx.textColor} ${_ctx.colorClass} ${_ctx.sizeClass}`)
    }, "  " + _toDisplayString(_ctx.APP_VERSION && !_ctx.isProduction ? _ctx.$t("app_version", { version: _ctx.APP_VERSION }) : ""), 3)
  ], 64))
}