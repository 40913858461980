import axios, { AxiosError, AxiosResponse } from "axios";
import store from "@/store";
import router from "@/router";
import { apiUri } from "./uri";
import useNotification from "@/composables/useNotification";
import { useCookies } from "vue3-cookies";
import i18n from "@/plugins/i18n";

const { cookies } = useCookies();

const controller = new AbortController();

const httpClient = axios.create({
  baseURL: apiUri,
});

httpClient.interceptors.request.use(
  (config: any) => {
    // add token to request header
    const token = store.getters.getToken || undefined;
    if (!config.headers) {
      config.headers = {};
    }
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    //add local time to server, maybe need to detect timezone
    // config.headers!.XTime = new Date().getTime();
    //detect version
    // config.headers["Access-Control-Allow-Origin"] = "*";
    // config.headers["Access-Control-Allow-Headers"] = "*";
    // config.headers["Access-Control-Allow-Methods"] = "*";
    // config.headers!.Version = process.env.APP_VERSION || "V1";
    config.signal = controller.signal;

    return config;
  },
  (error: any) => Promise.reject(error),
);

httpClient.interceptors.response.use(
  (response) => {
    // NOTE: 大抵の場合はレスポンスのdataだけを返す前提の作りになっているが、一部のAPIでは加工していないAxiosResponseが必要。
    //       リクエストのオプションでenvにrawEnvを指定すると、AxiosResponseをそのまま返すようにする。
    if ((response.config.env?.FormData as typeof rawEnv.FormData)?.useRawResponse) {
      return response;
    }
    return response.data;
  },
  async (error: unknown) => {
    if (error instanceof AxiosError) {
      const errorStatus = error?.response?.data?.status || error?.response?.data?.statusCode || undefined;
      const code = error?.response?.data?.code || "";
      const currentRoute = router.currentRoute || undefined;
      const currentRouteName = (currentRoute?.value?.name || "") as string;
      const isExceptions = ["forgot_password", "login", "forgot_password_confirm", "logout", "register", "change_password", "register_success"];

      if (isExceptions.indexOf(currentRouteName) < 0 && (errorStatus === 401 || code === "NotAuthorizedException")) {
        return onTokenRefreshError();
      }
    }

    return Promise.reject(error);
  },
);

export default httpClient;

export const rawEnv = {
  FormData: class {
    static get useRawResponse() {
      return true;
    }
  },
};

export const onTokenRefreshError = (res?: AxiosResponse) => {
  // NOTE: Must be called at the top of a `setup` function になるので、ここでuseI18nはできない

  const toast = useNotification();

  store.dispatch("logout");
  cookies.remove("refreshToken");
  toast.showError(res?.data.message || i18n.global.t("LoginSessionExpired"));
  router.push({ name: "login" });
};
